
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

const VerifyEmailTemplate = () => {
    const {logout} = useAuth0();
    return  (
        <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-2'>
        <div className='bg-white max-w-[600px] text-center rounded-xl shadow-md p-8'>
          <h1 className="text-blue-950 mb-3">Verify Your Email Address</h1>
          <p className="mb-4 text-[18px] text-gray-700">
            We've sent a verification link to your email address{" "}. Please
            check your inbox and click on the link to verify your email.
          </p>
          <p className="text-[16px] text-gray-600 mb-7">
            Didn't receive the email? Check your spam or junk folder.
          </p>
          <div className="flex gap-[15px] justify-center">
            <button
              onClick={() => 
                logout({ returnTo: window.location.origin })}
                className='bg-blue-950 hover:bg-blue-900 text-white rounded-lg px-5 py-2 text-[16px] cursor-pointer shadow-md transition-colors'
            //   onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
            //   onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
            >
              Go to Login Page
            </button>
            
          </div>
        </div>
      </div>
    
    );
};

export default VerifyEmailTemplate;
