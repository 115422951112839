import config from "config";

const getApiUrl = () => {
    const origin = window.location.origin;
    const isInternalIP = /^http:\/\/\d{3}\.\d{3}\.\d{2}\.\d{1,2}(:\d+)?$/.test(origin);
    if(isInternalIP)
        return {
            THOR_URL : config.REACT_APP_DEV_INTERNAL_URL,
            PROXY_URL : config.PROXY_INTERNAL_URL
        }
    else
        return {
            THOR_URL : config.REACT_APP_DEV_EXTERNAL_URL,
            PROXY_URL : config.PROXY_EXTERNAL_URL
        }
}

export const apiConfig = getApiUrl();