/* eslint-disable */
import setGraphElements from "../presentation/CreateGraph";
import config from "config";
import { apiConfig } from "apiConfig";
const getResults = (response, message) => {
    if (!response.ok) {
      throw new Error(message);
    }
    return response.json();
  }

  const getData = (setElements, setGraphResult, dispatch) => {
    fetch(apiConfig.PROXY_URL+'api/v1/db/connect', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify
      (
        { 
          host: config.DB_HOST, 
          port: config.DB_PORT, 
          user: config.DB_USER, 
          password: config.DB_PASSWORD, 
          database: config.DB_NAME, 
          cmd : "SELECT * FROM cypher("+config.GRAPH_NAME+", $$ MATCH (n)OPTIONAL MATCH (n)-[r]->(m) return n, r $$) as (n agtype, r agtype);"}),
    })
    .then(response => {
      return getResults(response, 'Network response was not ok')
    })
    .then(data => {
      setElements(setGraphElements(data.rows))
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }

// const getData = (setElements) => {
//     fetch('/api/v1/db/connect', {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ host: 'localhost', port: 5432, user: 'gui_user', password: 'gui_user', database: 'postgres'}),
//     })
//     .then(response => {
//       return getResults(response, 'Network response was not ok')
//     })
//     .then(data => {
//       fetch('/api/v1/cypher', {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ cmd : "SELECT * FROM cypher('infy_graph_with_sector', $$ match (n:Equity)-[r]-(m:Index) return n,r,m $$) as (n agtype, r agtype, m agtype);"}),
//         })
//         .then(response => {
//           return getResults(response, 'Cypher response was not ok')
//         })
//         .then(data => {
//            setElements(setGraphElements(data.rows))
//         })
//         .catch(error => {
//           console.error('There was a problem with the fetch operation:', error);
//         });
//     })
//     .catch(error => {
//       console.error('There was a problem with the fetch operation:', error);
//     });
//   }

export default getData;