
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({children}) => {
  const { isAuthenticated, error, isLoading, user } = useAuth0();

  const origin = window.location.origin;
  const isInternalIP = /^http:\/\/\d{3}\.\d{3}\.\d{2}\.\d{1,2}(:\d+)?$/.test(origin);

  if(isInternalIP){
    return children;
  }

  if (isLoading) {
    return <div></div>;
  }

  if(user && !user?.email_verified) 
    return <Navigate to="/verifyEmail" />

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
